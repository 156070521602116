<template>
  <div id="master">
    <v-navigation-drawer app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.account }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-btn text color="primary" @click="logoffDialog = true"
              >退出</v-btn
            >
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav shaped>
        <v-list-item-group v-model="menuSelected" color="primary">
          <v-list-item
            v-for="item in menuItems"
            :key="item.title"
            link
            :to="item.url"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app>
      <v-list-item>
        <v-list-item-icon class="mr-2">
          <v-icon v-text="menu.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="menu.title"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-title
          v-text="systemTitle"
          class="text-right text-h6"
        ></v-list-item-title>
      </v-list-item>
    </v-app-bar>
    <v-main
      ><v-container fluid><router-view></router-view> </v-container>
    </v-main>

    <v-footer app> </v-footer>
    <v-dialog v-model="logoffDialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> 确认 </v-card-title>

        <v-card-text> 您确定要退出登录码？ </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="logoffDialog = false"> 取消 </v-btn>

          <v-btn color="error" @click="logoff"> 确定 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  components: {},
  computed: {
    ...mapState(["systemTitle", "menus"]),
    menuItems() {
      let result = [this.menus[0]];
      if (this.user.permission) {
        let permission = this.user.permission.split(",");
        result = result.concat(
          this.menus.filter((p) => permission.some((pn) => pn == p.key))
        );
      }
      return result;
    },
  },
  methods: {
    ...mapMutations([]),
    logoff() {
      this.logoffDialog = false;
      this.$shareyueApi.logoff().then(() => {
        this.$shareyue.goToLogin();
      });
    },
  },
  watch: {
    $route: {
      handler: function () {
        this.menu = {};
        let meta = this.$route.meta;
        if (meta && meta.menu) {
          let index = this.menus.findIndex((p) => p.key == meta.menu);
          let menu = this.menus[index];
          if (menu) {
            this.menu = menu;
            this.menuSelected = index;
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.$shareyueApi
      .getUser()
      .then((user) => {
        this.user = user;
      })
      .catch(() => {});
  },
  data() {
    return {
      menu: {},
      menuSelected: 0,
      user: {},
      logoffDialog: false,
    };
  },
};
</script>
